<template>
  <div class="be-card h-100">
    <header-with-divider :text="$t('deposit.recharge_list')" color="blue" />
    <div
      class="d-flex align-items-center justify-content-between p-sm-4 pt-4 pb-4 pl-3 pr-3"
    >
      <BaseInputSearch :api-search="true" @search="search" />
      <button
        :class="{
          'i-left': !smallSize,
          'circle-btn': smallSize,
        }"
        class="be-btn primary green lg"
        @click="$router.push({ name: 'deposit.form' })"
      >
        <i class="icon-plus" />
        {{ !smallSize ? $t("deposit.new_recharge") : "" }}
      </button>
    </div>
    <div class="divider" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && deposits && deposits.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="deposits"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template #status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : statusClass(row.status),
            ]"
          >
            {{ $t(`status.${row.status.toLowerCase()}`) }}
          </span>
        </template>
        <template #created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM, YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
        <template #amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            {{ row.amount + " " + row.currency }}
          </div>
        </template>
        <template #phone="{ row }">
          <div class="d-flex flex-column">
            <span class="phone text-truncate">
              {{ row.svc_number || $t("deposit.no_specified") }}
            </span>
          </div>
        </template>
        <template #operator="{ row }">
          <div class="d-flex flex-column">
            <img
              :src="row.operator && row.operator.logo_url"
              :alt="(row.operator && row.operator.label) || ''"
              width="70"
            />
          </div>
        </template>
        <template #reference="{ row }">
          <div class="d-flex flex-column">
            {{ row.reference }}
          </div>
        </template>
        <template #actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <b-modal ref="detailsModal" no-fade size="lg" top>
        <div class="text-center" v-if="modalData">
          <b-row>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.operator") }}</div>
                <div>
                  {{ modalData.operator.label || $t("common.correction") }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("deposit.deposit_id") }}</div>
                <p class="break-all">
                  {{ modalData.svc_number || $t("deposit.no_specified") }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.fees") }}</div>
                <div>
                  {{ `${modalData.fee_amount} ${modalData.currency}` }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.amount") }}</div>
                <div>
                  {{ `${modalData.amount} ${modalData.currency}` }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.status") }}</div>
                <div :class="statusColorClass(modalData.status)">
                  {{ $t(`status.${modalData.status.toLowerCase()}`) }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.creation_date") }}</div>
                <div>
                  {{ modalData.created_at | moment("Do MMMM YYYY H:mm:ss") }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <template slot="modal-header">
          <span />
          <span class="modal-title">
            {{ $t("deposit.deposit_details") }} -
            {{ modalData && modalData.reference }}
          </span>
          <span @click="hideModal">
            <i class="icon-close f-24" />
          </span>
        </template>
        <template slot="modal-footer">
          <template
            v-if="
              modalData &&
                (modalData.status === 'Echec' ||
                  modalData.status === 'En cours')
            "
          >
            <span class="f-w-600 fermer mr-4" @click="hideModal">
              {{ $t("common.close") }}
            </span>
            <button
              class="ml-4 be-btn primary lg danger"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("common.create_a_ticket") }}
            </button>
          </template>
          <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
            {{ $t("common.close") }}
          </button>
        </template>
      </b-modal>
      <BasePagination
        v-if="deposits.length && last_page > 1 && !loading"
        :current_page="current_page"
        :last_page="last_page"
        @page="changePage"
      />
    </div>
    <no-content
      v-else
      :text="$t('deposit.start_with_a_recharge')"
      icon="icon-deposit"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BeTable from "@/components/BeTable";
import Loading from "@/components/common/Loading";
import NoContent from "@/components/common/NoContent";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import { OperationClassMixin } from "@/mixins/OperationClassMixin";

export default {
  name: "DepositList",
  mixins: [OperationClassMixin],
  components: {
    NoContent,
    BeTable,
    Loading,
    HeaderWithDivider,
  },
  data() {
    return {
      loading: false,
      modalData: null,
      fields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.identifier"),
          key: "phone",
        },
        {
          label: this.$t("common.operator"),
          key: "operator",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ],
      depositField: {
        page: 1,
        keyword: null,
      },
      smallTableFields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
          class: "break-all",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.identifier"),
          key: "phone",
        },
        {
          label: this.$t("common.operator"),
          key: "operator",
        },
        {
          label: "",
          key: "actions",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    ...mapGetters("deposit", ["deposits", "current_page", "last_page"]),
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
  },

  created() {
    this.getDepositList();
  },

  methods: {
    getDepositList() {
      this.loading = true;
      this.$store
        .dispatch("deposit/getDepositsList", this.depositField)
        .finally(() => (this.loading = false));
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search(text) {
      this.depositField.keyword = text;
      this.getDepositList();
    },
    changePage(page) {
      this.depositField.page = page;
      this.getDepositList();
    },
  },
};
</script>

<style scoped>
.phone {
  display: inline-block;
  max-width: 155px;
}

.hovered:hover .icon-arrow-right {
  display: block;
}

/* .icon-arrow-right {
  width: 36px;
  height: 36px;
  display: none;
  padding: 5px;
  color: #0071ff;
  cursor: pointer;
  border-radius: 25px;
  font-size: 25px;
} */

.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

.fermer {
  cursor: pointer;
}
</style>
